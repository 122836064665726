import {
  Dialog,
  DialogButton,
  EmptyState,
  Expansion,
  ExpansionDetails,
  ExpansionSummary,
  FilePreviewCard,
  FileUploadTextField,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  SelectionBar,
  Subheader,
  SubheaderText,
  useUploadAttachment,
} from '@ifca-ui/core'
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { DataFormFields, Option, UnitStatutoryInterface } from '../StatutoryDocumentInterface'
import {
  AddCircleOutline,
  AttachFile,
  Clear,
  PictureAsPdfSharp,
  RemoveCircleOutline,
  RestartAlt,
  Search,
} from '@mui/icons-material'
import EventIcon from '@mui/icons-material/Event'
import { DatePickerX } from 'components/Inputs/DatePickerX'
import { yupResolver } from '@hookform/resolvers/yup'
import { CommonYupValidation } from 'helpers/yup'
import { Controller, useForm } from 'react-hook-form'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import * as yup from 'yup'
import {
  MergeSelection,
  useAddSalesStatutoryDocumentMutation,
  useDeleteSalesStatutoryDocumentMutation,
  useEditSalesStatutoryDocumentMutation,
  useGetNotarySelectionListLazyQuery,
  useGetStatutoryTypeByUnitLazyQuery,
  useGetStatutoryUnitListQuery,
  useMergeSalesStatutoryDocumentMutation,
} from 'generated/graphql'
import { useParams } from 'react-router'
import moment from 'moment'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { styled } from '@mui/material/styles'

interface selectionField {
  unitId: string
  unitNo: string
  clusterName: string
  fullName: string
}

interface FileFormat {
  id: string
  documentRef: string
  statutoryDocumentId: string
  documentDate: string
  notaryId?: string
  bpnLandArea?: number
  unitStatutoryId: string
  contactId: string
  isMerge: boolean
  resourceUrl?: string
  fileName?: string
  mediaType?: string
}

export default function ByUnit() {
  const { projectID } = useParams()
  const selectionRef = useRef(null)
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null)
  const [dataList, setDataList] = useState<any[]>([])
  const [expanded, setExpanded] = useState<any[]>([])
  const [statutoryTypes, setStatutoryTypes] = useState<any>([])
  const [notaryList, setNotaryList] = useState<Option[]>([])
  const [editData, setEditData] = useState<FileFormat>()
  const [addData, setAddData] = useState<UnitStatutoryInterface>()
  const [mergeHandler, setMergeHandler] = useState<boolean>(false)
  const [checked, setChecked] = useState<MergeSelection[]>([])
  const [openDialogAttachment, setOpenDialogAttachment] = useState<boolean>()
  const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false)
  const [openDialogPreviewFile, setOpenDialogPreviewFile] = useState(null)
  const [imageScale, setImageScale] = useState<number>(1)
  const [type, setType] = useState<string>('')
  const statusMenuOpen = Boolean(statusAnchorEl)
  const [selectedChoice, setSelectedChoice] = useState<selectionField>({
    unitId: '',
    unitNo: '',
    clusterName: '',
    fullName: '',
  })

  const {
    error,
    loading,
    // data: { getStatutoryUnitList } = { getStatutoryUnitList: null },
    data: { getStatutoryUnitList } = { getStatutoryUnitList: [] },
  } = useGetStatutoryUnitListQuery({
    fetchPolicy: 'network-only',
    variables: {
      projectId: projectID,
    },
  })
  if (error) console.log(error)

  const [mergeSalesStatutoryDocument] = useMergeSalesStatutoryDocumentMutation({
    onCompleted: data => {
      getStatutoryTypeByUnit({
        variables: {
          unitId: selectedChoice.unitId,
          projectId: projectID,
        },
      })
    },
  })

  const [editSalesStatutoryDocument] = useEditSalesStatutoryDocumentMutation({
    onCompleted: data => {
      getStatutoryTypeByUnit({
        variables: {
          unitId: selectedChoice.unitId,
          projectId: projectID,
        },
      })
    },
  })

  const [addSalesStatutoryDocument] = useAddSalesStatutoryDocumentMutation({
    onError: err => {
      console.log(err)
    },
    onCompleted: () => {
      clearErrors()
      reset()
      handleRemove(0)
      handleDialogClosed()
      setSnackBar(SystemMsgs.createNewRecord())
      getStatutoryTypeByUnit({
        variables: {
          unitId: selectedChoice.unitId,
          projectId: projectID,
        },
      })
    },
  })

  const [getStatutoryTypeByUnit] = useGetStatutoryTypeByUnitLazyQuery({
    onCompleted(data) {
      setStatutoryTypes(data.getStatutoryTypeByUnit)

      let arr = []
      for (let i in data.getStatutoryTypeByUnit) {
        arr.push(false)
      }
      expanded.forEach((el, i) => { if (el === true) arr[i] = el })
      setExpanded(arr)
    },
  })

  const [deleteSalesStatutoryDocument] =
    useDeleteSalesStatutoryDocumentMutation({
      onCompleted: () => {
        getStatutoryTypeByUnit({
          variables: {
            unitId: selectedChoice.unitId,
            projectId: projectID,
          },
        })
      },
    })
  const [getNotarySelectionList] =
    useGetNotarySelectionListLazyQuery({
      onCompleted(data) {
        setNotaryList(data.getNotarySelectionList)
      },
    })

  const formSchema = yup.object().shape({
    documentDate: CommonYupValidation.requireDate('Date is required.'),
    documentRef: CommonYupValidation.requireField(
      'Document number is required'
    ),
  })

  const {
    handleSubmit,
    control,
    clearErrors,
    setError,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<DataFormFields>({
    mode: 'all',
    defaultValues: {
      documentDate: new Date(),
      documentRef: '',
      notaryId: null
    },
    resolver: yupResolver(formSchema),
  })

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget)
  }

  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null)
  }

  const handleStatusMenuItemClick = doc => {
    // setSelectedChoice(doc)
    // setStatusAnchorEl(null)
    // getStatutoryTypeByUnit({
    //   variables: {
    //     unitId: doc.unitId,
    //     projectId: projectID,
    //   },
    // })
    if (doc !== null) {
      setSelectedChoice(doc)
      setStatusAnchorEl(null)
      getStatutoryTypeByUnit({
        variables: {
          unitId: doc.unitId,
          projectId: projectID,
        },
      })

      getNotarySelectionList({
        variables: {
          projectId: projectID
        }
      })
    } else {
      setSelectedChoice({
        clusterName: '',
        fullName: '',
        unitId: '',
        unitNo: '',
      })
    }
  }

  const {
    files,
    handleUploadChange,
    handleRemove,
    previewFiles,
    setFiles,
    setPreviewFiles,
    resetUploadedFiles
  } = useUploadAttachment()

  const attachmentDialog = (val: boolean, type: string) => {
    setType(type)
    setOpenDialogAttachment(val)
  }

  const handleExpand = (index: number) => {
    const res = expanded.map((el, i) => {
      if (i == index) el = !el
      else el = false
      return el
    })
    setChecked([])
    setMergeHandler(false)
    setExpanded(res)
  }

  const deletePreparation = (val, selected) => {
    setOpenDialogDelete(val)
    setEditData(selected)
  }

  const handleDialogClosed = () => {
    setOpenDialogDelete(false)
    setOpenDialogAttachment(false)
    setOpenDialogPreviewFile(null)
    clearErrors()
    resetUploadedFiles()
  }

  const prepareEdit = (data: FileFormat) => {
    setEditData(data)
    setValue('documentRef', data.documentRef)
    setValue('documentDate', new Date(data.documentDate.split('T')[0]))
    setValue('notaryId', data.notaryId)
    setValue('bpnLandArea', data.bpnLandArea)
    attachmentDialog(true, 'edit')

    if (data.resourceUrl && data.fileName && data.mediaType) {
      const fileObj = []
      const tempFiles = []
      const tempPreviewFiles = []

      fileObj.push(new File([data.resourceUrl], data.fileName, {
        type: data.mediaType
      }))
      tempFiles.push(fileObj[0])
      tempPreviewFiles.push(data.resourceUrl)

      setFiles(tempFiles)
      setPreviewFiles(tempPreviewFiles)
    }

    getNotarySelectionList({
      variables: {
        projectId: projectID
      }
    })
  }

  const prepareAdd = (data: UnitStatutoryInterface) => {
    reset()
    setAddData(data)
    attachmentDialog(true, 'add')

    getNotarySelectionList({
      variables: {
        projectId: projectID
      }
    })
  }

  const prepareHandleUploadChange = (e: ChangeEvent) => {
    if ((e.target as HTMLInputElement).files !== null) {
      const fileObj = []
      const tempFiles = []
      const tempPreviewFiles = []

      fileObj.push((e.target as HTMLInputElement).files)
      tempFiles.push(fileObj[0][0])
      tempPreviewFiles.push(URL.createObjectURL(fileObj[0][0]))

      setFiles(tempFiles)
      setPreviewFiles(tempPreviewFiles)
    }
  }

  const saveAttachment = () => {
    const { documentDate, documentRef, notaryId, bpnLandArea } = watch()
    // if (files.length > 0) {
    //   if (type == 'merge') {
    //     let arr = checked.map(el => {
    //       return {
    //         id: el.id,
    //         documentDate: el.documentDate,
    //         documentRef: el.documentRef,
    //         statutoryDocumentId: el.statutoryDocumentId,
    //         unitStatutoryId: el.unitStatutoryId,
    //         contactId: el.contactId,
    //       }
    //     })
    //     mergeSalesStatutoryDocument({
    //       variables: {
    //         input: {
    //           documentDate,
    //           document_ref: documentRef,
    //           statutoryDocumentId: arr[0].statutoryDocumentId,
    //           checked: arr,
    //           projectId: projectID,
    //           unitStatutoryId: arr[0].unitStatutoryId,
    //           contactId: arr[0].contactId,
    //         },
    //       },
    //       onCompleted: () => {
    //         reset()
    //         handleRemove(0)
    //         handleDialogClosed()
    //         setSnackBar(SystemMsgs.updateRecord())
    //         getStatutoryTypeByUnit({
    //           variables: {
    //             unitId: selectedChoice.unitId,
    //             projectId: projectID,
    //           },
    //         })
    //         setChecked([])
    //         setMergeHandler(false)
    //       },
    //     })
    //   } else if (type == 'edit') {
    //     editSalesStatutoryDocument({
    //       variables: {
    //         input: {
    //           id: editData.id,
    //           documentDate: documentDate,
    //           document_ref: documentRef,
    //           notaryId: notaryId,
    //           bpnLandArea: +bpnLandArea,
    //           file: files,
    //         },
    //       },
    //       onCompleted: () => {
    //         reset()
    //         handleRemove(0)
    //         handleDialogClosed()
    //         setSnackBar(SystemMsgs.updateRecord())
    //         getStatutoryTypeByUnit({
    //           variables: {
    //             unitId: selectedChoice.unitId,
    //             projectId: projectID,
    //           },
    //         })
    //       },
    //     })
    //   } else if (type === 'add') {
    //     addSalesStatutoryDocument({
    //       variables: {
    //         input: {
    //           documentDate: documentDate,
    //           document_ref: documentRef,
    //           file: files,
    //           notaryId: notaryId,
    //           bpnLandArea: Number(bpnLandArea),
    //           statutoryDocumentId: addData.statutoryDocId,
    //           unitStatutoryId: addData.unitStatutoryId,
    //           saleId: addData.saleId,
    //           contactId: addData.contactId,
    //           projectId: projectID,
    //         },
    //       }
    //     })
    //   }
    // } else {
    //   return setSnackBar('File must not be empty.')
    // }
    if (type === 'merge') {
      let arr = checked.map(el => {
        return {
          id: el.id,
          documentDate: el.documentDate,
          documentRef: el.documentRef,
          statutoryDocumentId: el.statutoryDocumentId,
          unitStatutoryId: el.unitStatutoryId,
          contactId: el.contactId,
        }
      })
      mergeSalesStatutoryDocument({
        variables: {
          input: {
            documentDate,
            document_ref: documentRef,
            statutoryDocumentId: arr[0].statutoryDocumentId,
            checked: arr,
            projectId: projectID,
            unitStatutoryId: arr[0].unitStatutoryId,
            contactId: arr[0].contactId,
            file: files,
          },
        },
        onCompleted: () => {
          reset()
          handleRemove(0)
          handleDialogClosed()
          setSnackBar(SystemMsgs.updateRecord())
          getStatutoryTypeByUnit({
            variables: {
              unitId: selectedChoice.unitId,
              projectId: projectID,
            },
          })
          setChecked([])
          setMergeHandler(false)
        },
      })
    } else if (type === 'edit') {
      editSalesStatutoryDocument({
        variables: {
          input: {
            id: editData.id,
            documentDate: documentDate,
            document_ref: documentRef,
            notaryId: notaryId,
            bpnLandArea: +bpnLandArea,
            file: files,
          },
        },
        onCompleted: () => {
          reset()
          handleRemove(0)
          handleDialogClosed()
          setSnackBar(SystemMsgs.updateRecord())
          getStatutoryTypeByUnit({
            variables: {
              unitId: selectedChoice.unitId,
              projectId: projectID,
            },
          })
        },
      })
    } else if (type === 'add') {
      addSalesStatutoryDocument({
        variables: {
          input: {
            documentDate: documentDate,
            document_ref: documentRef,
            file: files,
            notaryId: notaryId,
            bpnLandArea: Number(bpnLandArea),
            statutoryDocumentId: addData.statutoryDocId,
            unitStatutoryId: addData.unitStatutoryId,
            saleId: addData.saleId,
            contactId: addData.contactId,
            projectId: projectID,
          },
        }
      })
    }
  }

  const doDeleteAttachment = () => {
    deleteSalesStatutoryDocument({
      variables: {
        id: editData.id,
      },
      onCompleted: () => {
        setOpenDialogDelete(false)
        setSnackBar(SystemMsgs.deleteRecord())
        getStatutoryTypeByUnit({
          variables: {
            unitId: selectedChoice.unitId,
            projectId: projectID,
          },
        })
      },
    })
  }

  const handleDialogPreviewClosed = () => {
    setOpenDialogAttachment(true)
    setOpenDialogPreviewFile(null)
    clearErrors()
  }


  const pdfDialog = (
    <Dialog
      open={!!openDialogPreviewFile}
      fullWidth={true}
      onClose={() => {
        setOpenDialogAttachment(true)
        setOpenDialogPreviewFile(null)
        clearErrors()
      }}
      body={
        <>
          <Box sx={{ height: '80vh', padding: 2, }} >
            {previewFiles?.map((file, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative'
                }}
              >
                {files[index]?.type?.includes('pdf') ? (
                  <iframe
                    src={openDialogPreviewFile}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      display: 'block'
                    }}
                  />
                ) : (
                    <>
                      <img
                        src={openDialogPreviewFile}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          display: 'block',
                          margin: 'auto',
                          transform: `scale(${imageScale})`,
                          transition: 'transform 0.4s ease',
                          transformOrigin: 'top left',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'fixed',
                          bottom: 20,
                          left: 0,
                          right: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <IconButton
                          style={{ padding: 1 }}
                          color='primary'
                          onClick={() => setImageScale(prev => prev * 0.8)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                        <IconButton
                          style={{ padding: 1 }}
                          color='primary'
                          onClick={() => setImageScale(prev => 1)}
                        >
                          <RestartAlt />
                        </IconButton>
                        <IconButton
                          style={{ padding: 1 }}
                          color='primary'
                          onClick={() => setImageScale(prev => prev * 1.2)}
                        >
                          <AddCircleOutline />
                        </IconButton>
                      </Box>
                    </>
                )}
              </div>
            ))}
          </Box>
        </>
      }
      footer={{
        buttons: [
          {
            children: 'Close',
            color: 'primary',
            onClick: () => handleDialogPreviewClosed(),
          },
        ],
      }}
    />
  )


  const AttachmentDialog = openDialogAttachment && (
    <Dialog
      fullWidth={true}
      open={openDialogAttachment}
      onClose={() => {
        setOpenDialogAttachment(false)
        clearErrors()
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Statutory Documents
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    {selectedChoice?.unitNo}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <Box width="100%">
            <DatePickerX
              required
              control={control}
              name="documentDate"
              label="Date"
            />
          </Box>
          <Box width="100%">
            <Controller
              control={control}
              name="documentRef"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Document Number"
                  autoComplete="off"
                  error={errors?.documentRef ? true : false}
                  helperText={errors?.documentRef ? errors?.documentRef?.message : ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                />
              )}
            />
          </Box>
          <Box>
            <FileUploadTextField
              label="Upload Document"
              value={files}
              variant="standard"
              margin="normal"
              multiple={false}
              // onChange={e => handleUploadChange(e)}
              onChange={e => prepareHandleUploadChange(e)}
              filePreview={
                <>
                  {previewFiles?.map((file, index) => (
                    <span key={index} style={{ paddingRight: '20px' }}>
                      <Badge
                        className={'override-icon-button'}
                        style={{ marginRight: '0px', width: '93px' }}
                        badgeContent={
                          <IconButton
                            onClick={() => {
                              handleRemove(index)
                            }}
                          >
                            <Clear />
                          </IconButton>
                        }
                        color="secondary"
                      >
                        <FilePreviewCard
                          key={index}
                          label={files[index]?.name}
                          badge={{
                            invisible: true,
                          }}
                        >
                          {files[index]?.type?.includes('pdf') ? (
                            <PictureAsPdfSharp
                              style={{ color: '#e2574c', cursor: 'pointer' }}
                              onClick={() => {
                                setOpenDialogPreviewFile(file)
                                setOpenDialogAttachment(false)
                              }}

                            />
                          ) : (
                              <Avatar variant="square" src={file}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpenDialogPreviewFile(file)
                                  setOpenDialogAttachment(false)
                                }}
                              />
                          )}
                        </FilePreviewCard>
                      </Badge>
                    </span>
                  ))}
                </>
              }
            />
          </Box>
          <Box width="100%">
            <Controller
              control={control}
              name="notaryId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  id="clear-on-blur"
                  fullWidth
                  clearOnBlur
                  options={notaryList as Option[]}
                  getOptionLabel={opt =>
                    typeof opt === 'object' ? opt.name : ''
                  }
                  value={notaryList.find(e => e.notaryId === value) as Option || null}
                  onChange={(e, value: any) =>
                    setValue('notaryId', value?.notaryId || null)
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Notary Public"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Notary Name"
                    />
                  )}
                />
              )}
            />
          </Box>
          <Box width="100%" marginY={2}>
            <Controller
              control={control}
              name="bpnLandArea"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="BPN Land Area"
                  placeholder="-"
                  autoComplete="off"
                  error={errors?.bpnLandArea ? true : false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || ''}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Box>
        </>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => handleDialogClosed(),
          },

          {
            children: 'Save',
            color: 'primary',
            // onClick: () => saveAttachment(),
            onClick: handleSubmit(saveAttachment),
          },
        ],
      }}
    ></Dialog>
  )

  const DeleteAttachmentDialog = openDialogDelete && (
    <Dialog
      fullWidth={true}
      open={openDialogDelete}
      onClose={() => {
        setOpenDialogDelete(false)
      }}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Statutory Documents
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    {selectedChoice?.unitNo}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Typography className="text-xsTitle">
          Are you sure want to delete?
        </Typography>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => handleDialogClosed(),
          },

          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => {
              doDeleteAttachment()
            },
          },
        ],
      }}
    ></Dialog>
  )

  // const checklistHandler = (file: FileFormat) => {
  //   let arr = []
  //   if (checked.length < 1) arr.push(file)
  //   else {
  //     if (checked.includes(file)) {
  //       arr = checked.filter(el => el.id !== file.id)
  //     } else {
  //       arr = checked
  //       arr.push(file)
  //     }
  //   }
  //   if (arr.length >= 2) setMergeHandler(true)
  //   else setMergeHandler(false)
  //   setChecked(arr)

  //   console.log('arr: ', arr);

  // }

  const checklistHandler = (doc: FileFormat) => {
    setChecked(prevChecked => {
      const isChecked = prevChecked.some(item => item.id === doc.id);
      if (!isChecked) {
        if (prevChecked.length === 1) setMergeHandler(true);
        return [...prevChecked, doc];
      } else {
        const updatedChecked = prevChecked.filter(item => item.id !== doc.id);
        if (updatedChecked.length < 2) setMergeHandler(false);
        return updatedChecked;
      }
    });
  }

  useEffect(() => {
    setExpanded([])
  }, [])

  return (
    <>
      {AttachmentDialog}
      {DeleteAttachmentDialog}
      {pdfDialog}
      <Box ref={selectionRef}>
        {/* <SelectionBar
          title={
            <Typography
              component="span"
              className="text-mdLabel"
              variant="inherit"
            >
              {selectedChoice.unitNo == ''
                ? 'Select Data'
                : getStatutoryUnitList?.find(
                    el => el.clusterName == selectedChoice.clusterName
                  )?.clusterName +
                  '/' +
                  getStatutoryUnitList?.find(
                    el => el.unitNo == selectedChoice.unitNo
                  )?.unitNo +
                  (selectedChoice.fullName
                    ? ' | ' +
                      getStatutoryUnitList?.find(
                        el => el.unitNo == selectedChoice.unitNo
                      ).fullName
                    : '')}
            </Typography>
          }
          selectionItem={{
            style: {
              background: selectedChoice.unitNo !== '' ? '#ff9800' : '',
            },
            onClick: e => handleStatusClick(e),
          }}
        /> */}

        {/* Search Bar Autocomplete Custom */}
        <Autocomplete
          disablePortal
          className="search-dropdown-sales"
          options={getStatutoryUnitList ?? []}
          // value={selectedUnit as any}
          getOptionLabel={su =>
            typeof su !== 'string'
              ? `${su.clusterName}/${su.unitNo} ${su.fullName ? ' | ' + su.fullName : ''
              }`
              : su
          }
          sx={{
            paddingY: 0.8,
          }}
          style={{
            width: '100%',
            zIndex: 100,
            background: '#fff',
            boxShadow:
              '0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1)',
          }}
          popupIcon={<Search sx={{ color: '#212121' }} fontSize="small" />}
          // sx={{
          //   [`& .${autocompleteClasses.popupIndicator}`]: {
          //     transform: 'none',
          //   },
          // }}
          onChange={(e, unit) => {
            handleStatusMenuItemClick(unit)
          }}
          renderInput={params => {
            const { InputLabelProps, InputProps, ...rest } = params
            return (
              <>
                <InputBase
                  {...params.InputProps}
                  {...rest}
                  placeholder={`Search Here${getStatutoryUnitList?.length > 0
                    ? '(' + getStatutoryUnitList.length + ')'
                    : ''
                    }`}
                  style={{
                    width: '100%',
                    fontSize: '12px',
                    paddingLeft: 12,
                    fontWeight: 500,
                  }}
                  value={selectedChoice}
                />
              </>
            )
          }}
          PaperComponent={props => (
            <Paper
              {...props}
              style={{
                width: '100%',
                marginRight: 0,
                fontSize: '12px',
                fontWeight: 600,
              }}
              sx={{
                marginTop: 1,
                // marginLeft: -1.5
              }}
            />
          )}
        />

        <Menu
          id="default-menu"
          divRef={selectionRef}
          anchorEl={statusAnchorEl}
          open={statusMenuOpen}
          onClose={handleStatusMenuClose}
          MenuListProps={{
            role: 'listbox',
          }}
        >
          {getStatutoryUnitList?.map((option, index) => (
            <MenuItem
              key={index}
              onClick={e => handleStatusMenuItemClick(option)}
            >
              {option.clusterName +
                '/' +
                option.unitNo +
                (option.fullName ? ' | ' + option.fullName : '')}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {selectedChoice.unitNo !== '' ? (
        <>
          {statutoryTypes.length ? (
            expanded.map((el, i) => (
              <Box style={{ marginTop: 4 }} key={'box' + i}>
                <Expansion
                  expanded={el}
                  defaultExpanded={false}
                  key={'expansion' + i}
                >
                  <ExpansionSummary
                    style={{ backgroundColor: '#faf2e8' }}
                    key={'expansion-sum' + i}
                    className="mortgage-expansion-summary"
                    expandIcon={
                      <IconButton
                        sx={{ p: 0, color: 'common.black' }}
                        onClick={e => handleExpand(i)}
                      >
                        {expanded ? (
                          <ExpandMoreOutlinedIcon />
                        ) : (
                          <ExpandMoreOutlinedIcon />
                        )}
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={
                        <Box component="div" display="flex">
                          <Typography
                            className="text-xsTitle"
                            color="#454545"
                            variant="inherit"
                            component="span"
                            flex="1"
                          >
                            {statutoryTypes[i]?.name}
                          </Typography>
                          <Box
                            className="text-desc"
                            component="span"
                            display="flex"
                          >
                            <Box component="div" display="flex">
                              {parseInt(statutoryTypes[i]?.totalUploaded) <
                                parseInt(
                                  statutoryTypes[i]?.totalNeedToBeUploaded
                                ) && (
                                  <IconButton
                                    sx={{ p: 0, color: 'common.black' }}
                                    onClick={() => {
                                      // attachmentDialog(true, 'add')
                                      prepareAdd(statutoryTypes[i])
                                    }}
                                  >
                                    <AttachFile className="text-title" />
                                  </IconButton>
                                )}
                              <Typography className="search-bar-count-f text-desc">
                                {statutoryTypes[i]?.totalUploaded}
                              </Typography>
                              /
                              <Typography className="text-desc">
                                {statutoryTypes[i]?.totalNeedToBeUploaded}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      }
                    />
                  </ExpansionSummary>
                  <ExpansionDetails>
                    {/* {statutoryTypes[i].documents.length > 0 && (
                      <>
                        {statutoryTypes[i].documents.map(
                          (file: FileFormat, index: string) => (
                            <ListItem
                              key={'listitem' + index}
                              onClick={() => {}}
                              secondaryAction={
                                <>
                                  {!file.isMerge && (
                                    <Box
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <IconButton
                                        style={{ padding: 1 }}
                                        onClick={e => prepareEdit(file)}
                                      >
                                        <EditIcon className="text-smTitle" />
                                      </IconButton>
                                      <IconButton
                                        style={{ padding: 1 }}
                                        onClick={e =>
                                          deletePreparation(true, file)
                                        }
                                      >
                                        <DeleteIcon
                                          color="error"
                                          className="text-smTitle"
                                        />
                                      </IconButton>
                                    </Box>
                                  )}
                                </>
                              }
                            >
                              <ListItemText
                                key={'listitemtext' + index}
                                primary={
                                  <Box
                                    color="common.black"
                                    display="flex"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <Checkbox
                                      size="medium"
                                      disabled={file.isMerge}
                                      name={file.documentRef}
                                      onChange={() => checklistHandler(file)}
                                      checked={checked.includes(file)}
                                    />
                                    <Typography
                                      component="div"
                                      variant="inherit"
                                      className="text-xsTitle custom-label-box text-overflow"
                                      onClick={e =>
                                        !file.isMerge ? prepareEdit(file) : ''
                                      }
                                    >
                                      {moment(file.documentDate).format(
                                        'DD MMM YYYY'
                                      )}
                                    </Typography>
                                    <Typography
                                      component="div"
                                      className="text-xsTitle custom-label-box text-overflow"
                                      variant="inherit"
                                      onClick={e =>
                                        !file.isMerge ? prepareEdit(file) : ''
                                      }
                                    >
                                      {file.documentRef}
                                    </Typography>
                                  </Box>
                                }
                              />
                              {file.isMerge && (
                                <hr
                                  style={{
                                    width: '100%',
                                    margin: 'auto 0 auto -12.5px',
                                    top: '50%',
                                    position: 'absolute',
                                    borderColor: '#454545',
                                  }}
                                />
                              )}
                            </ListItem>
                          )
                        )}
                        <Box
                          style={{
                            paddingTop: 8,
                            paddingBottom: 5,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <DialogButton
                            disabled={!mergeHandler}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              attachmentDialog(true, 'merge')
                            }}
                          >
                            Merge
                          </DialogButton>
                        </Box>
                      </>
                    )} */}
                    {statutoryTypes[i]?.documents.length > 0 && (
                      <>
                        {statutoryTypes[i]?.documents.map(
                          (file: FileFormat, index: string) => (
                            <div key={'div' + index}>
                              <Box
                                sx={{
                                  width: '100%',
                                  borderColor: 'grey.500',
                                }}
                                display={'flex'}
                                gap={1.5}
                                // key={file.id}
                                key={'box' + index}
                              >
                                <Box
                                  sx={{ width: '5%', height: '5%' }}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  alignSelf={'center'}
                                >
                                  <Checkbox
                                    disabled={file.isMerge}
                                    name={file.documentRef}
                                    onChange={() => checklistHandler(file)}
                                    // checked={checked.includes(file)}
                                    checked={checked.some(item => item.id === file.id)}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    width: '42.5%',
                                    marginY: 2,
                                  }}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={0.5}
                                >
                                  <Controller
                                    control={control}
                                    name="documentDate"
                                    render={({
                                      field: { onChange, onBlur, value },
                                    }) => (
                                      <MobileDatePicker
                                        InputProps={{ readOnly: true }}
                                        readOnly
                                        disabled={file.isMerge}
                                        label="Doc Date"
                                        onChange={newValue => {
                                          onChange(newValue)
                                        }}
                                        value={file.documentDate.split('T')[0]}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            margin="normal"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            sx={{ marginTop: 0 }}
                                            value={
                                              file.documentDate.split('T')[0]
                                            }
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    style={{ padding: 1 }}
                                                    disabled={file.isMerge}
                                                  >
                                                    <EventIcon />
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  />

                                  <TextField
                                    label="Notary Public"
                                    autoComplete="off"
                                    disabled={file.isMerge}
                                    error={!!errors?.notaryId}
                                    helperText={errors?.notaryId?.message}
                                    value={notaryList?.find(v => v.notaryId === file.notaryId)?.name || ''}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    placeholder='-'
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    width: '42.5%',
                                    marginY: 2,
                                  }}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  alignSelf={'center'}
                                  gap={1.5}
                                >
                                  <TextField
                                    required
                                    disabled={file.isMerge}
                                    id="outlined-required"
                                    label="Doc No"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    size="small"
                                    value={file.documentRef}
                                    InputProps={{ readOnly: true }}
                                  />
                                  <TextField
                                    disabled={file.isMerge}
                                    id="outlined-required"
                                    label="BPN Land Area"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="-"
                                    fullWidth
                                    size="small"
                                    value={file.bpnLandArea || ''}
                                    InputProps={{ readOnly: true }}
                                  />
                                </Box>
                                <Box
                                  sx={{ width: '10%' }}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  alignSelf={'flex-start'}
                                  marginTop={2}
                                >
                                  {/* <IconButton style={{ padding: 1 }}>
                                    <AttachFile />
                                  </IconButton> */}
                                  <IconButton
                                    style={{ padding: 1 }}
                                    color='error'
                                    disabled={file.isMerge}
                                    onClick={e =>
                                      deletePreparation(true, file)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                  <IconButton
                                    style={{ padding: 1 }}
                                    disabled={file.isMerge}
                                    onClick={e => prepareEdit(file)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                              {file.isMerge && (
                                <hr
                                  style={{
                                    width: '100%',
                                    position: 'absolute',
                                    margin: '-63px 0 0 -16px',
                                    borderColor: '#454545'
                                  }}
                                />
                              )}
                              <Divider />
                            </div>
                          )
                        )}
                        <Box
                          style={{
                            paddingTop: 13,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <DialogButton
                            disabled={!mergeHandler}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              attachmentDialog(true, 'merge')
                            }}
                          >
                            Merge
                          </DialogButton>
                        </Box>
                      </>
                    )}
                    {statutoryTypes[i]?.documents.length == 0 && (
                      <EmptyState
                        title="No Record found"
                        subTitle="Add a new record now."
                      />
                    )}
                  </ExpansionDetails>
                </Expansion>
              </Box>
            ))
          ) : (
            <EmptyState
              title="No Record found"
              subTitle="Add a new record now."
            />
          )}
        </>
      ) : (
        <>
          <EmptyState
            title="No Record found"
            subTitle="Add a new record now."
          />
        </>
      )}
    </>
  )
}

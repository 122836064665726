import { lazy } from 'react'
import { RouteObject } from 'react-router'
import PurchaserDetailsPreview from './tabs/purchaser/PurchaserDetailsPreview'
import { DiscountDetail } from './tabs/pricing/discount/DiscountDetail'

const SalesRegistrationsListing = lazy(() =>
  import('./SalesRegistrationsListing').then(module => ({
    default: module.SalesRegistrationsListing,
  }))
)

const SalesRegistrationsForm = lazy(() => import('./SalesRegistrationsForm'))

const SalesRegistrationsFormPreview = lazy(
  () => import('./preview/SalesRegistrationsFormPreview')
)

const SalesRegistrationsPdfPreview = lazy(() => import('./SalesRegistrationsPdfPreview'))

const CarParkInstallmentDetail = lazy(
  () => import('./preview/CarParkInstallmentDetail')
)
const PackageInstallmentDetail = lazy(
  () => import('./preview/PackageInstallmentDetail')
)

const SalesRegistrationsPreview = lazy(
  () => import('./SalesRegistrationsPreview')
)

const DiscountListing = lazy(
  () => import('./tabs/pricing/discount/DiscountListing')
)

const PurchaserInfoForm = lazy(
  () => import('./tabs/purchaser/PurchaserInfoForm')
)

const UnitPrice = lazy(() => import('./tabs/pricing/UnitPrice'))

const UnitPricePreview = lazy(() => import('./tabs/pricing/UnitPricePreview'))

const CarPark = lazy(() => import('./tabs/pricing/carpark/CarPark'))

const CarParkForm = lazy(() => import('./tabs/pricing/carpark/CarParkForm'))

const InstallmentCarParkForm = lazy(
  () => import('./tabs/pricing/carpark/InstallmentCarParkForm')
)

const CarParkDetails = lazy(
  () => import('./tabs/pricing/carpark/CarParkDetails')
)

const CarParkPaymentDetails = lazy(
  () => import('./tabs/pricing/carpark/CarParkPaymentDetails')
)
const InstallmentCarPark = lazy(
  () => import('./tabs/pricing/carpark/InstallmentCarPark')
)

const CarParkPreview = lazy(
  () => import('./tabs/pricing/carpark/CarParkPreview')
)

const AdditionalPackage = lazy(
  () => import('./tabs/pricing/packages/AdditionalPackage')
)

const AdditionalPackagePreview = lazy(
  () => import('./tabs/pricing/packages/AdditionalPackagePreview')
)

const AdditionalPackageForm = lazy(
  () => import('./tabs/pricing/packages/AdditionalPackageForm')
)

const AdditionalPackageInstallmentScheduleForm = lazy(
  () => import('./tabs/pricing/packages/AdditionalPackageInstallmentSchedule')
)

const InstallmentScheduleForm = lazy(
  () => import('./tabs/paymentPlan/InstallmentScheduleForm')
)

const AdditionalPackageDetails = lazy(
  () => import('./tabs/pricing/packages/AdditionalPackageDetails')
)

const PackagePaymentDetails = lazy(
  () => import('./tabs/pricing/packages/PackagePaymentDetails')
)

const InstallmentPackage = lazy(
  () => import('./tabs/pricing/packages/InstallmentPackage')
)

const ContactPersonForm = lazy(() => import('./tabs/others/ContactPersonForm'))

const SalesRegistrationsRoutes: RouteObject[] = [
  {
    path: 'sales-registrations',
    children: [
      {
        path: '',
        element: <SalesRegistrationsListing />,
      },
      {
        path: ':saleId/preview/:templateId',
        element: <SalesRegistrationsPdfPreview />,
      },
      {
        path: 'form',
        element: <SalesRegistrationsForm mode="add" />,
      },
      {
        path: 'form/:saleId',
        children: [
          {
            path: '',
            element: <SalesRegistrationsForm mode="add" />,
          },
          {
            path: 'preview',
            element: <SalesRegistrationsFormPreview />,
          },
          {
            path: 'preview/car-park/:carParkId',
            element: <CarParkInstallmentDetail />,
          },
          {
            path: 'preview/package/:packageId',
            element: <PackageInstallmentDetail />,
          },
          {
            path: 'purchaser-info/add',
            element: <PurchaserInfoForm mode="add" formMode="Add" />,
          },
          {
            path: 'purchaser-info/editJointBuyer',
            element: (
              <PurchaserInfoForm
                mode="editJointBuyer"
                formMode="EditJointBuyer"
              />
            ),
          },
          {
            path: 'discount',
            element: <DiscountListing />,
          },
          {
            path: 'discount/details',
            element: <DiscountDetail />,
          },
          {
            path: 'purchaser-info/editMainBuyer',
            element: (
              <PurchaserInfoForm
                mode="editMainBuyer"
                formMode="EditMainBuyer"
              />
            ),
          },
          {
            path: 'unit-price',
            element: <UnitPrice />,
          },
          {
            path: 'car-park',
            element: <CarPark />,
          },
          {
            path: 'car-park/form',
            element: <CarParkForm mode="add" formMode="add" />,
          },
          {
            path: 'car-park/form/:carParkId',
            element: <CarParkForm mode="edit" formMode="edit" />,
          },
          {
            path: 'car-park/add/installment-schedule/:paymentPlanId',
            element: <InstallmentCarParkForm mode="add" formMode="add" />,
          },
          {
            path: 'car-park/add/installment-schedule/:paymentPlanId/:carParkId',
            element: <InstallmentCarParkForm mode="add" formMode="add" />,
          },
          {
            path: 'car-park/details/:carParkId',
            element: <CarParkDetails mode="details" />,
          },
          {
            path: 'car-park/details/:carParkId/:paymentPlanDetailsId',
            element: <CarParkPaymentDetails mode="detailspayment" />,
          },
          {
            path: 'car-park/details/:carParkId/:paymentPlanDetailsId/form',
            element: <InstallmentCarPark mode="detailspaymentform" />,
          },
          {
            path: 'additional-package',
            element: <AdditionalPackage />,
          },
          {
            path: 'additional-package/view/:packageId',
            element: <AdditionalPackageDetails />,
          },
          {
            path: 'additional-package/form',
            element: <AdditionalPackageForm mode="add" />,
          },
          {
            path: 'additional-package/form/installment-schedule/:paymentPlanId',
            element: <AdditionalPackageInstallmentScheduleForm />,
          },
          {
            path: 'additional-package/form/installment-schedule/:paymentPlanId/:packageId',
            element: <AdditionalPackageInstallmentScheduleForm />,
          },
          {
            path: 'additional-package/form/:packageId',
            element: <AdditionalPackageForm mode="edit" />,
          },
          {
            path: 'additional-package/details/:packageId',
            element: <PackagePaymentDetails mode="detailspayment" />,
          },
          {
            path: 'additional-package/details/:packageId/:paymentPlanDetailsId/form',
            element: <InstallmentPackage mode="detailspaymentform" />,
          },
          {
            path: 'installment-schedule/form',
            element: <InstallmentScheduleForm />,
          },
          {
            path: 'contact-person/:contactId',
            element: <ContactPersonForm mode="add" />,
          },
        ],
      },
      {
        path: 'form/preview/:saleId',
        children: [
          {
            path: '',
            element: <SalesRegistrationsPreview />,
          },
          {
            path: 'purchaser-info/:contactId',
            element: <PurchaserDetailsPreview />,
          },
          {
            path: 'unit-price',
            element: <UnitPricePreview />,
          },
          {
            path: 'car-park',
            element: <CarParkPreview />,
          },
          {
            path: 'car-park/details',
            element: <CarParkDetails mode="details" />,
          },
          {
            path: 'additional-package',
            element: <AdditionalPackagePreview />,
          },
          {
            path: 'additional-package/:packageId',
            element: <AdditionalPackageDetails />,
          },
        ],
      },
    ],
  },
]

export default SalesRegistrationsRoutes

import { RouteObject } from 'react-router'
import PurchaserAccountSummary from '../PurchaserAccountsIndo/PurchaserAccountSummary'
import PurchaserLedgerRoutes from '../PurchaserAccountsIndo/PurchaserLedger/Routes'
import PAIndoMiscBillingRoutes from './MiscBilling/Routes'
import PAIndoDebitNoteRoutes from './DebitNote/Routes'
import PAIndoCreditNoteRoutes from './CreditNote/Routes'
import PAIndoRebateRoutes from './Rebate/Routes'
import PAIndoPaymentRoutes from './Payment/Routes'
import PAIndoDepositRoutes from './Deposit/Routes'
import PAIndoContraRoutes from './Contra/Routes'
import PAIndoRefundRoutes from './Refund/Routes'
import PAIndoPurchaserInstallmentRoutes from './PurchaserInstallment/Routes'
import PAIndoLateInterestRoutes from './LateInterest/Routes'
import PAIndoAdditionalPackagesInstallmentRoutes from './AdditionalPackages/Routes'
import PAIndoCarkParkInstallmentRoutes from './CarParkInstallment/Routes'

export const SP_REMINDER_PATH = {
  LISTING: '/:projectID/purchaser-accounts-indo',
}

//PA = purchasers' accounts
const PurchaserAccountsIndoRoutes: RouteObject[] = [
  {
    path: 'purchaser-accounts-indo',
    children: [
      {
        path: '',
        element: <PurchaserAccountSummary />,
      },
      {
        path: '',
        children: [
          ...PAIndoPurchaserInstallmentRoutes,
          ...PurchaserLedgerRoutes,
          ...PAIndoCarkParkInstallmentRoutes,
          ...PAIndoMiscBillingRoutes,
          ...PAIndoLateInterestRoutes,
          ...PAIndoDebitNoteRoutes,
          ...PAIndoCreditNoteRoutes,
          ...PAIndoRebateRoutes,
          ...PAIndoPaymentRoutes,
          ...PAIndoDepositRoutes,
          ...PAIndoContraRoutes,
          ...PAIndoRefundRoutes,
          ...PAIndoAdditionalPackagesInstallmentRoutes
        ],
      },
    ],
  },
]

export default PurchaserAccountsIndoRoutes

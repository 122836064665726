import { lazy } from 'react'
import { RouteObject } from 'react-router'
const PAIndoLateInterestListing = lazy(
  () => import('./PAIndoLateInterestListing')
)
const PAIndoLateInterestDetail = lazy(
  () => import('./PAIndoLateInterestDetail')
)

//PA = purchaser's accounts
const PAIndoLateInterestRoutes: RouteObject[] = [
  {
    path: 'late-interest',
    children: [
      {
        path: '',
        element: <PAIndoLateInterestListing tableRef="late_interest" />,
      },
      {
        path: ':id/view',
        element: <PAIndoLateInterestDetail />,
      },
    ],
  },
]

export default PAIndoLateInterestRoutes

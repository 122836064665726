import { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoPurchaserInstallmentListing = lazy(
  () => import('./PAIndoPurchaserInstallmentListing')
)
const PAIndoPurchaserInstallmentDetails = lazy(
  () => import('./PAIndoPurchaserInstallmentDetails')
)

const PAIndoPurchaserInstallmentRoutes: RouteObject[] = [
  {
    path: 'purchaser-installment',
    children: [
      {
        path: '',
        element: <PAIndoPurchaserInstallmentListing />,
      },
      {
        path: ':saleID/view',
        element: <PAIndoPurchaserInstallmentDetails />,
      }
    ],
  },
]

export default PAIndoPurchaserInstallmentRoutes

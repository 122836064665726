import { lazy } from 'react'
import { RouteObject } from 'react-router'

const NupListing = lazy(() => import('./NupListing'))
const NupDetails = lazy(() => import('./NupDetails'))

const NupRoutes: RouteObject[] = [
  {
    path: 'nup',
    children: [
      {
        path: '',
        element: <NupListing />,
      },
      {
        path: ':nupId/detail',
        element: <NupDetails />,
      },
    ],
  },
]

export default NupRoutes
import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const LoanReleaseNotificationListing =  lazy (() => import ('./LoanReleaseNotificationListing'))
const LoanReleaseNotificationDraftListing = lazy (() => import('./LoanReleaseNotificationDraftListing'))
const LoanReleaseNotificationDraftForm = lazy (() => import('./LoanReleaseNotificationDraftForm'))
const LoanReleaseNotificationDetails = lazy (() => import('./LoanReleaseNotificationDetails'))
const LoanReleaseNotificationSubmittedListing = lazy (() => import ('./LoanReleaseNotificationSubmittedListing'))
const LoanReleaseNotificationSubmittedDetails = lazy (() => import ('./LoanReleaseNotificationSubmittedDetails'))
const LoanReleaseNotificationApprovedListing = lazy(() => import ('./LoanReleaseNotificationApprovedListing'))
const LoanReleaseNotificationApprovedDetails = lazy (() => import ('./LoanReleaseNotificationApprovedDetails'))
const LoanReleaseNotificationPdfPreview = lazy(() => import ('./LoanReleaseNotificationPdfPreview'))

export const LOAN_RELEASE_NOTIFICATION_PATH = {
    LOAN_RELEASE_NOTIFICATION_LISTING: './:projectID/loan-release-notification-listing',
    LOAN_RELEASE_NOTIFICATION_DRAFT_LISTING: './:projectID/loan-release-notification-draft-listing',
    LOAN_RELEASE_NOTIFICATION_DRAFT_ADD_FORM: './:projectID/loan-release-notification-draft-listing/add',
    LOAN_RELEASE_NOTIFICATION_DRAFT_EDIT_FORM: './:projectID/loan-release-notification-draft-listing/edit/:releaseId',
    LOAN_RELEASE_NOTIFICATION_SUBMIT_APPROVED: './:projectID/loan-release-notification-submit-approve/:releaseId',
    LOAN_RELEASE_NOTIFICATION_SUBMITTED_LISTING: './:projectID/loan-release-notification-submitted-listing',
    LOAN_RELEASE_NOTIFICATION_SUBMITTED_DETAILS: './:projectID/loan-release-notification-submitted-listing/details',
    LOAN_RELEASE_NOTIFICATION_APPROVED_LISTING: './:projectID/loan-release-notification-approved-listing',
    LOAN_RELEASE_NOTIFICATION_APPROVED_DETAILS: './:projectID/loan-release-notification-approved-listing/details'
}

const LoanReleaseNotificationRoutes: RouteObject [] = [
    {
        path: '',
        children: [
            {
                path:'loan-release-notification-draft-listing',
                element: <LoanReleaseNotificationDraftListing/>
            },
            {
                path:'loan-release-notification-draft-listing/add',
                element: <LoanReleaseNotificationDraftForm mode="add"/>
            },
            {
                path:'loan-release-notification-draft-listing/edit/:releaseId',
                element:<LoanReleaseNotificationDraftForm mode="edit"/>
            },
            {
                path:'loan-release-notification-submit-approve/:releaseId',
                element:<LoanReleaseNotificationDetails mode="edit"/>
            },
            {
                path:'loan-release-notification-submitted-listing',
                element:<LoanReleaseNotificationSubmittedListing/>
            },
            {
                path:'loan-release-notification-submitted-listing/details',
                element: <LoanReleaseNotificationSubmittedDetails/>
            },
            {
                path:'loan-release-notification-approved-listing',
                element: <LoanReleaseNotificationApprovedListing/>
            },
            {
                path:'loan-release-notification-approved-listing/details',
                element: <LoanReleaseNotificationApprovedDetails/>
            },
            {
                path:'loan-release-notification-listing',
                element: <LoanReleaseNotificationListing/>
            },
            {
                path: 'loan-release-notification-listing/:releaseId/preview/:templateId',
                element: <LoanReleaseNotificationPdfPreview />
            }
        ]
    }
]

export default LoanReleaseNotificationRoutes
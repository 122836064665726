import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PAIndoCarParkInstallmentUnitListing = lazy(
  () => import('./PAIndoCarParkInstallmentUnitListing')
)

const PAIndoCarParkInstallmentListing = lazy(
  () => import('./PAIndoCarParkInstallmentListing')
)

const PAIndoCarParkInstallmentDetail = lazy(
  () => import('./PAIndoCarParkInstallmentDetail')
)

const PAIndoCarkParkInstallmentRoutes: Array<RouteObject> = [
  {
    path: 'car-park-installment',
    children: [
      {
        path: '',
        element: <PAIndoCarParkInstallmentUnitListing />,
      },
      {
        path: ':unitId',
        element: <PAIndoCarParkInstallmentListing />,
      },
      {
        path: 'detail/:carParkId',
        element: <PAIndoCarParkInstallmentDetail />,
      },
    ],
  },
]

export default PAIndoCarkParkInstallmentRoutes

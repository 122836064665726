import {
  ColumnFlex,
  Container,
  List,
  ListItem,
  ListItemText,
  SubHeaderContainer,
} from '@ifca-ui/core'
import { KeyboardArrowRight } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { IconButton, Typography, useMediaQuery } from '@mui/material'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import { RowFlex } from 'components/Layout/RowLayout'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  CreditControlStatus,
  useGetPaIndoMiscBillingSummaryLazyQuery,
  useGetPaIndoSummaryLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { amtStr } from 'helpers/numFormatter'
import { useContext, useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useLocation, useNavigate, useParams } from 'react-router'
import { formatNumbertoK } from 'helpers/formatNumberToK'

const PurchaserAccountSummary = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const subHeaderRef = useRef(null)
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  let location = useLocation()
  let stateData: any = location?.state
  const { projectID }: any = useParams()
  const [showPage, setShowPage] = useState<boolean>(false)
  const path = `/${projectID}/purchaser-accounts-indo`
  const [saleID, setSaleID] = useState<string>('')
  const [miscBillingSummary, setMiscBillingSummary] = useState({
    totalDraft: 0,
    totalSubmitted: 0,
    totalApproved: 0
  })

  const [isGreaterThanOrEqualTrillion, setIsGreaterThanOrEqualTrillion] = useState<boolean>(false)
  
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  //#region API

  const { data: { projectDetail } = { projectDetail: null } } =
    useProjectDetailQuery({ variables: { id: projectID } })

  const [
    fetchPAIndoModuleSummary,
    {
      loading: loadingPAmoduleSummary,
      data: { getPAIndoSummary } = {
        getPAIndoSummary: null,
      },
    },
  ] = useGetPaIndoSummaryLazyQuery({
    onCompleted: data => {
      const isGreaterThanOrEqualTrillion = submenuList.some(item => item.amount >= 1e12) ||
        getPAIndoSummary?.miscBillingSummary?.totalUnallocatedAmt >= 1e12 ||
        getPAIndoSummary?.debitNoteSummary?.totalUnallocatedAmt >= 1e12 ||
        getPAIndoSummary?.creditNoteSummary?.totalUnallocatedAmt >= 1e12 ||
        getPAIndoSummary?.rebateSummary?.totalUnallocatedAmt >= 1e12 ||
        getPAIndoSummary?.paymentSummary?.totalUnallocatedAmt >= 1e12 ||
        getPAIndoSummary?.depositSummary?.totalUnallocatedAmt >= 1e12 ||
        getPAIndoSummary?.contraSummary?.totalUnallocatedAmt >= 1e12 ||
        getPAIndoSummary?.refundSummary?.totalUnallocatedAmt >= 1e12;
      setIsGreaterThanOrEqualTrillion(isGreaterThanOrEqualTrillion)
      setShowPage(true)
    },
  })

  const [
    getPaIndoMiscBillingSummary,
    {
      loading: PAIndoMiscBillingLoading,
    }
  ] = useGetPaIndoMiscBillingSummaryLazyQuery({
    onCompleted: data => {
      const totalDraft = data.getPAIndoMiscBillingSummary.filter(miscBilling => miscBilling.status === CreditControlStatus.Draft).length

      const totalSubmitted = data.getPAIndoMiscBillingSummary.filter(miscBilling => {
        const currentDate = new Date()
        const {documentDate, creditTerm, status, documentNo, generationBatch, generationBatchDate } = miscBilling

        const adjustedDate = new Date(documentDate)
        adjustedDate.setDate(adjustedDate.getDate() + (creditTerm || 0));

        return (adjustedDate <= currentDate && status === CreditControlStatus.Submitted && !documentNo && !generationBatch && !generationBatchDate)

      }).length 

      const totalApproved = data.getPAIndoMiscBillingSummary.filter(miscBilling => miscBilling.status === CreditControlStatus.Approved).length
      
      setMiscBillingSummary({
        totalDraft,
        totalSubmitted,
        totalApproved
      })
    },
  })

  useEffect(() => {
    if (projectID != null) {
      fetchPAIndoModuleSummary({
        variables: {
          projectId: projectID,
        },
      })

      getPaIndoMiscBillingSummary({
        variables: {
          projectId: projectID
        }
      })
    }
  }, [projectID])

  //#endregion

  const submenuDescription = (tableRef, draft, submit, approve, unAllocAmt) => {
    // return (
    //   <ColumnFlex gap="5px">
    //     <div></div>
    //     <RowFlex mainAxisAlignment="SPACE_BETWEEN">
    //       <RowFlex gap="10px" key="3 item">
    //         <RowFlex gap="4px">
    //           <Typography className="text-desc" color="primary">
    //             {draft}
    //           </Typography>
    //           <Typography className="text-desc">Draft</Typography>
    //         </RowFlex>
    //         <RowFlex gap="4px">
    //           <Typography className="text-desc" color="primary">
    //             {submit}
    //           </Typography>
    //           <Typography className="text-desc">Submitted</Typography>
    //         </RowFlex>
    //         <RowFlex gap="4px">
    //           <Typography className="text-desc" color="primary">
    //             {approve}
    //           </Typography>
    //           <Typography className="text-desc">Approved</Typography>
    //         </RowFlex>
    //       </RowFlex>
    //       {tableRef !== 'refund' ? (
    //         <RowFlex>
    //           <Typography className="text-xsTitle" color="red">
    //             {(isMobile && isGreaterThanOrEqualTrillion) ? formatNumbertoK(unAllocAmt) : amtStr(unAllocAmt)}
    //           </Typography>
    //         </RowFlex>
    //       ) : null}
    //     </RowFlex>
    //   </ColumnFlex>
    // )
    return !tableRef.includes('installment') ? (
      <ColumnFlex gap="5px">
        <div></div>
        <RowFlex mainAxisAlignment="SPACE_BETWEEN">
          <RowFlex gap={isMobile ? '8px' : '10px'} key="3 item">
            {isMobile ? (
              <>
                {tableRef !== 'late-interest' && (
                  <>
                    <ColumnFlex gap="4px" crossAxisAlignment='center'>
                      <Typography className="text-desc" color="primary">
                        {draft}
                      </Typography>
                      <Typography className="text-desc">Draft</Typography>
                    </ColumnFlex>
                    <ColumnFlex gap="4px" crossAxisAlignment='center'>
                      <Typography className="text-desc" color="primary">
                        {submit}
                      </Typography>
                      <Typography className="text-desc">Submitted</Typography>
                    </ColumnFlex>
                  </>
                )}
                <ColumnFlex gap="4px" crossAxisAlignment='center'>
                  <Typography className="text-desc" color="primary">
                    {approve}
                  </Typography>
                  <Typography className="text-desc">Approved</Typography>
                </ColumnFlex>
              </>
            ) : (
              <>
                {tableRef !== 'late-interest' && (
                  <>
                    <RowFlex gap="4px">
                      <Typography className="text-desc" color="primary">
                        {draft}
                      </Typography>
                      <Typography className="text-desc">Draft</Typography>
                    </RowFlex>
                    <RowFlex gap="4px">
                      <Typography className="text-desc" color="primary">
                        {submit}
                      </Typography>
                      <Typography className="text-desc">Submitted</Typography>
                    </RowFlex>
                  </>
                )}
                <RowFlex gap="4px">
                  <Typography className="text-desc" color="primary">
                    {approve}
                  </Typography>
                  <Typography className="text-desc">Approved</Typography>
                </RowFlex>
              </>
            )}
          </RowFlex>
          {tableRef !== 'refund' ? (
            <RowFlex>
              <Typography className="text-xsTitle" color="red">
                {(isMobile && isGreaterThanOrEqualTrillion) ? formatNumbertoK(unAllocAmt) : amtStr(unAllocAmt)}
              </Typography>
            </RowFlex>
          ) : null}
        </RowFlex>
      </ColumnFlex>
    ) : (
      <ColumnFlex gap="5px">
        <div></div>
        <RowFlex mainAxisAlignment="SPACE_BETWEEN">
          <RowFlex gap="10px" key="3 item">
            <RowFlex gap="4px">
              <Typography className="text-desc">
                {typeof draft === 'number' ? amtStr(draft) : draft}
              </Typography>
            </RowFlex>
          </RowFlex>
          <RowFlex>
            <Typography className="text-xsTitle" color="red">
              {(isMobile && isGreaterThanOrEqualTrillion) ? formatNumbertoK(unAllocAmt) : amtStr(unAllocAmt)}
            </Typography>
          </RowFlex>
        </RowFlex>
      </ColumnFlex>
    )
  }

  //#region header/subheader
  useEffect(() => {
    if (subHeaderRef.current?.clientHeight) {
      rootDispatch({
        type: 'subHeaderHeight',
        payload: subHeaderRef.current?.clientHeight,
      })
    }
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navTo(`/${projectID}`),
          },
        },
        rightIcon: {},
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Purchaser Accounts',
          },
        },
      },
    })
  }, [projectDetail])
  //#endregion

  const submenuList = [
    {
      title: 'Purchaser Ledger',
      path: `${path}/purchaser-ledger`,
      description: showPage ? (
        <Typography component="span" className="text-desc">
          -
        </Typography>
      ) : null,
    },
    {
      title: 'Purchaser Installment',
      amount: showPage ? getPAIndoSummary?.purchaserInstallmentSummary?.totalDocAmt : 0,
      path: `${path}/purchaser-installment`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'installment',
            getPAIndoSummary?.purchaserInstallmentSummary?.totalDocAmt,
            null,
            null,
            getPAIndoSummary?.purchaserInstallmentSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Additional Packages',
      amount: showPage ? getPAIndoSummary?.additionalPackageSummary?.totalDocAmt : 0,
      path: `${path}/additional-packages`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'packages-installment',
            `${amtStr(
                  getPAIndoSummary?.additionalPackageSummary?.totalDocAmt
                )} | ${getPAIndoSummary?.additionalPackageSummary?.itemCount} ${getPAIndoSummary?.additionalPackageSummary?.itemCount > 1
                  ? 'Items'
                  : 'Item'
                }`,
            null,
            null,
            getPAIndoSummary?.additionalPackageSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Car Park Installment',
      amount: getPAIndoSummary?.carParkSummary?.totalOutstandingAmount,
      path: `${path}/car-park-installment`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'installment',
            `
              ${amtStr(getPAIndoSummary?.carParkSummary?.totalOutstandingAmount)}
              |
              ${getPAIndoSummary?.carParkSummary?.itemCount} Unit
            `,
            null,
            null,
            `${getPAIndoSummary?.carParkSummary?.totalAllocateAmount}`
          )}
        </>
      ),
    },
    {
      title: 'Misc Billing',
      amount: showPage ? getPAIndoSummary?.miscBillingSummary?.totalDocAmt : 0,
      path: `${path}/misc-billing`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'misc',
            miscBillingSummary.totalDraft,
            miscBillingSummary.totalSubmitted,
            miscBillingSummary.totalApproved,
            getPAIndoSummary?.miscBillingSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Late Interest',
      amount: showPage ? getPAIndoSummary?.lateInterestSummary?.totalCharges : 0,
      path: `${path}/late-interest`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'late-interest',
            0,
            0,
            getPAIndoSummary?.lateInterestSummary?.totalApproved,
            getPAIndoSummary?.lateInterestSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Debit Note',
      amount: showPage ? getPAIndoSummary.debitNoteSummary?.totalDocAmt : 0,
      path: `${path}/debit-note`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'debit-note',
            getPAIndoSummary?.debitNoteSummary?.draftCount,
            getPAIndoSummary?.debitNoteSummary?.submitCount,
            getPAIndoSummary?.debitNoteSummary?.approveCount,
            getPAIndoSummary?.debitNoteSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Credit Note',
      amount: showPage ? getPAIndoSummary.creditNoteSummary?.totalDocAmt : 0,
      path: `${path}/credit-note`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'credit-note',
            getPAIndoSummary?.creditNoteSummary?.draftCount,
            getPAIndoSummary?.creditNoteSummary?.submitCount,
            getPAIndoSummary?.creditNoteSummary?.approveCount,
            getPAIndoSummary?.creditNoteSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Rebate',
      amount: showPage ? getPAIndoSummary.rebateSummary?.totalDocAmt : 0,
      path: `${path}/rebate`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'rebate',
            getPAIndoSummary?.rebateSummary?.draftCount,
            getPAIndoSummary?.rebateSummary?.submitCount,
            getPAIndoSummary?.rebateSummary?.approveCount,
            getPAIndoSummary?.rebateSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Payment',
      amount: showPage ? getPAIndoSummary.paymentSummary?.totalDocAmt : 0,
      path: `${path}/payment`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'payment',
            getPAIndoSummary?.paymentSummary?.draftCount,
            getPAIndoSummary?.paymentSummary?.submitCount,
            getPAIndoSummary?.paymentSummary?.approveCount,
            getPAIndoSummary?.paymentSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Deposit',
      amount: showPage ? getPAIndoSummary.depositSummary?.totalDocAmt : 0,
      path: `${path}/deposit`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'deposit',
            getPAIndoSummary?.depositSummary?.draftCount,
            getPAIndoSummary?.depositSummary?.submitCount,
            getPAIndoSummary?.depositSummary?.approveCount,
            getPAIndoSummary?.depositSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
    {
      title: 'Contra',
      amount: showPage ? getPAIndoSummary.contraSummary.totalDocAmt : 0,
      path: `${path}/contra`,
      rightTextColor: 'primary',
      rightIconColor: 'primary',
      description: (
        <>
          <ColumnFlex gap="5px">
            <div></div>
            <RowFlex mainAxisAlignment="SPACE_BETWEEN">
              <RowFlex gap="10px">
                {isMobile ? (
                  <>
                    <ColumnFlex gap="4px" crossAxisAlignment='center'>
                      <Typography className="text-desc" color="primary">
                        {getPAIndoSummary?.contraSummary?.approveCount}
                      </Typography>
                      <Typography className="text-desc">Records</Typography>
                    </ColumnFlex>
                  </>
                ): (
                  <>
                    <RowFlex gap="4px">
                      <Typography className="text-desc" color="primary">
                        {getPAIndoSummary?.contraSummary?.approveCount}
                      </Typography>
                      <Typography className="text-desc">Records</Typography>
                    </RowFlex>
                  </>
                )}
              </RowFlex>
              <RowFlex>
                <Typography className="text-xsTitle" color="red">
                  {(isMobile && isGreaterThanOrEqualTrillion) 
                    ? formatNumbertoK(getPAIndoSummary?.contraSummary?.totalUnallocatedAmt) 
                    : amtStr(getPAIndoSummary?.contraSummary?.totalUnallocatedAmt)
                  }
                </Typography>
              </RowFlex>
            </RowFlex>
          </ColumnFlex>
        </>
      ),
    },
    {
      title: 'Refund',
      amount: showPage ? getPAIndoSummary.refundSummary?.totalDocAmt : 0,
      path: `${path}/refund`,
      rightTextColor: 'primary',

      rightIconColor: 'primary',
      description: (
        <>
          {submenuDescription(
            'refund',
            getPAIndoSummary?.refundSummary?.draftCount,
            getPAIndoSummary?.refundSummary?.submitCount,
            getPAIndoSummary?.refundSummary?.approveCount,
            getPAIndoSummary?.refundSummary?.totalUnallocatedAmt
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <SubHeaderContainer
        id="subheader-container"
        ref={subHeaderRef}
        style={{ backgroundColor: '#ffe7cb', fontSize: 'smaller' }}
      ></SubHeaderContainer>
      <Container bottomSpacing="lg">
        <>
          <List>
            {submenuList?.map(el => {
              return (
                <ListItem
                  key={el.title}
                  secondaryAction={
                    <IconButton>
                      <KeyboardArrowRight />
                    </IconButton>
                  }
                  onClick={() => {
                    navTo(el?.path)
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography component="div" display="flex">
                        <Typography
                          component="div"
                          flex="1"
                          display="flex"
                          variant="inherit"
                          color="common.black"
                        >
                          <Typography component="span" className="text-xsTitle">
                            {el?.title}
                          </Typography>
                        </Typography>
                        <Typography
                          component="div"
                          flex="1"
                          display="flex"
                          variant="inherit"
                          justifyContent="right"
                          alignItems="start"
                        >
                          <Typography
                            component="span"
                            color={el?.rightTextColor}
                            className="text-xsTitle"
                          >
                            {el.amount < 0 && '('}
                            {/* <NumberFormat
                              value={el?.amount}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            /> */}
                            {el.amount != null 
                              ? (isMobile && isGreaterThanOrEqualTrillion 
                                  ? formatNumbertoK(el.amount)
                                  : amtStr(el.amount) 
                                ) 
                              : ''
                            }
                            {el.amount < 0 && ')'}
                          </Typography>
                        </Typography>
                      </Typography>
                    }
                    secondary={<div> {el?.description}</div>}
                  />
                </ListItem>
              )
            })}
          </List>
        </>
      </Container>
    </>
  )
}

export default PurchaserAccountSummary

import { lazy } from 'react'
import { RouteObject } from 'react-router'

const UnitBookingListing = lazy(() => import('./UnitBookingListing'))
const UnitBookingDetails = lazy(() => import('./UnitBookingDetails'))
const UnitBookingFeeListing = lazy(() => import('./UnitBookingFeeListing'))
const UnitBookingFeeDetails = lazy(() => import('./UnitBookingFeeDetails'))
const UnitBookingJointBuyerDetails = lazy(() => import('./UnitBookingJointBuyerDetails'))
const UnitBookingBuyerDetails = lazy(() => import('./UnitBookingBuyerDetails'))
const UnitBookingForm = lazy(() => import('./UnitBookingForm'))
const UnitBookingFeeForm = lazy(() => import('./UnitBookingFeeForm'))
const UnitBookingBuyerForm = lazy(() => import('./UnitBookingBuyerForm'))
const UnitBookingFeePdfPreview = lazy(() => import('./UnitBookingFeePdfPreview'))

const UnitBookingRoutes: RouteObject[] = [
  {
    path: 'unit-booking',
    children: [
      {
        path: '',
        element: <UnitBookingListing />,
      },
      {
        path: ':saleId/detail',
        element: <UnitBookingDetails />,
      },
      {
        path: ':saleId/unit-booking-fee',
        element: <UnitBookingFeeListing />,
      },
      {
        path: ':saleId/unit-booking-fee/:salePaymentId/preview/:templateId',
        element: <UnitBookingFeePdfPreview />
      },
      {
        path: ':saleId/unit-booking-fee/:salePaymentId/details',
        element: <UnitBookingFeeDetails />,
      },
      {
        path: ':saleId/detail/:contactId',
        element: <UnitBookingBuyerDetails/>,
      },
      {
        path: ':saleId/detail/:contactId/jointbuyers/:jointBuyerId',
        element: <UnitBookingJointBuyerDetails/>,
      },
      {
        path: 'add',
        element: <UnitBookingForm /> ,
      },
      {
        path: 'add/purchaser',
        element: <UnitBookingBuyerForm mode='add' backtrack='UnitBooking'/>,
      },
      {
        path: 'add/purchaser/:detailId/edit',
        element: <UnitBookingBuyerForm mode='edit' backtrack='UnitBooking'/>,
      },
      {
        path: ':saleId/unit-booking-fee/add',
        element: <UnitBookingFeeForm />,
      },
      {
        path: ':saleId/unit-booking-fee/:salePaymentId/edit',
        element: <UnitBookingFeeForm mode='edit'/>,
      },
    ],
  },
]

export default UnitBookingRoutes
import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const StampDutyIntegrationListing = lazy(() => import('./StampDutyIntegrationListing'));
const StampDutyIntegrationForm = lazy(() => import('./StampDutyIntegrationForm'));
const StampDutyIntegrationDetail = lazy(() => import('./StampDutyIntegrationDetail'));

const stampDutyIntegrationRoutes: RouteObject[] = [
  {
    path: 'stamp-duty-integration',
    children: [
      {
        path: '',
        element: <StampDutyIntegrationListing />,
      },
      {
        path: 'add',
        children: [
          {
            path: '',
            element: <StampDutyIntegrationForm mode="add" />,
          },
        ],
      },
      {
        path: ':stampDutyIntegrationId/edit',
        children: [
          {
            path: '',
            element: <StampDutyIntegrationForm mode="edit" />,
          },
        ],
      },
      {
        path: ':stampDutyIntegrationId/view',
        element: <StampDutyIntegrationDetail />,
      },
    ]
  },
];

export default stampDutyIntegrationRoutes;

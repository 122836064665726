import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const PaymentReminderGenerationListing = lazy(
  () => import('./PaymentReminderGenerationListing')
)
const PaymentReminderGenerationDetail = lazy(
  () => import('./PaymentReminderGenerationDetail')
)
const PaymentReminderGenerationForum = lazy(
  () => import('./PaymentReminderGenerationForm')
)
const PaymentReminderGenerationPdfPreview = lazy(
  () => import('./PaymentReminderGenerationPdfPreview')
)

const PaymentReminderGenerationIndoRoutes: RouteObject[] = [
  {
    path: 'payment-reminder-generation',
    children: [
      {
        path: '',
        element: <PaymentReminderGenerationListing />,
      },
      {
        path: 'add',
        element: <PaymentReminderGenerationForum mode="add" />,
      },
      {
        path: ':reminderId/detail',
        children: [
          {
            path: '',
            element: <PaymentReminderGenerationDetail />,
          },
          {
            path: 'preview/:templateId',
            element: <PaymentReminderGenerationPdfPreview />
          }
          
          // {
          //   path: 'edit',
          //   element: <OverduedPurchaserIndoForm mode="edit" />,
          // },
          // {
          //   path: 'view',
          //   element: <OverduedPurchaserIndoView />,
          // },
        ],
      },
    ],
  },
]

export default PaymentReminderGenerationIndoRoutes

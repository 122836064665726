import React, { lazy } from 'react'
import { RouteObject } from 'react-router'

const AdditionalPackagesInstallmentListing = lazy(
  () => import('./AdditionalPackagesInstallmentListing')
)
const AdditionalPackagesInstallmentDetailListing = lazy(
  () => import('./AdditionalPackagesInstallmentDetailListing')
)
const AdditionalPackagesInstallmentDetail = lazy(
  () => import('./AdditionalPackagesInstallmentDetail')
)

const PAIndoAdditionalPackagesInstallmentRoutes: RouteObject[] = [
  {
    path: 'additional-packages',
    children: [
      {
        path: '',
        element: <AdditionalPackagesInstallmentListing />,
      },
      {
        path: ':saleID',
        element: <AdditionalPackagesInstallmentDetailListing />,
      },
      {
        path: ':saleID/detail/:packageId',
        element: <AdditionalPackagesInstallmentDetail />,
      },
    ],
  },
]

export default PAIndoAdditionalPackagesInstallmentRoutes

import React, { lazy } from 'react'
import { RouteObject } from 'react-router'
import AreaVarianceSubmenuRoutes from 'containers/modules/AreaVariance/Routes'
import BankLoanRoutes from 'containers/modules/BankLoan/Routes'
import BridgingLoanRoutes from 'containers/modules/BridgingLoan/Routes'
import CreditControlRoutes from 'containers/modules/CreditControl/Routes'
import CreditControlIndoRoutes from 'containers/modules/CreditControlIndo/Routes'
import DownpaymentReminderRoutes from 'containers/modules/DownpaymentReminder/Routes'
import KeyCollectionRoutes from 'containers/modules/KeyCollection/Routes'
import LADRoutes from 'containers/modules/LAD/Routes'
import MOTRoutes from 'containers/modules/MOTTracking/Routes'
import ProgressMiscBillingRoutes from 'containers/modules/ProgressMiscBilling/Routes'
import PropertyTitleRoutes from 'containers/modules/PropertyTitle/Routes'
import SalesCancellationRoutes from 'containers/modules/SalesCancellation/Routes'
import SalesContractRoutes from 'containers/modules/SalesContracts/Routes'
import SalesRegistrationsRoutes from 'containers/modules/SalesRegistrations/Routes'
import SpReminderRoutes from 'containers/modules/SPReminder/Routes'
import VacantPossessionRoutes from 'containers/modules/VacantPossession/Routes'
import PurchaserAccountsIndoRoutes from '../PurchaserAccountsIndo/Routes'
import CollectionRoutes from '../Collection/Routes'
import StakeholderRoutes from '../Stakeholder/Routes'
import SubsaleSubmenuRoutes from '../Subsale/Routes'
import UnitTransferIndoRoutes from '../UnitTransferIndo/Routes'
import AccountJournalRoutes from '../AccountJournal/Routes'
import UnitBookingRoutes from '../UnitBooking/Routes'
import LoanApprovalRoutes from '../LoanApproval/Routes'
import LoanDifferentialRoutes from '../LoanDifferential/Routes'
import PaymentScheduleRoutes from '../PaymentSchedules/Routes'
import LandDepositRoutes from '../LandDeposit/Routes'
import LoanReleaseNotificationRoutes from '../LoanReleaseNotification/Routes'
import LoanPaymentRoutes from '../LoanPayment/Routes'
import RefinancingRoutes from '../Refinancing/Routes'
import BuyBackRoutes from '../BuyBack/Routes'
import StatutoryDocumentsRoutes from '../StatutoryDocuments/Routes'
import PurchaserNameTransferRoutes from '../PurchaserNameTransfer/Routes'
import AreaVarianceIndoRoutes from 'containers/modules/AreaVarianceIndo/Routes'
import NupRoutes from '../NUP/Routes'
const SalesAdministrationSubmenu = lazy(
  () => import('./SalesAdministrationSubmenu')
)

export const SALES_ADMINISTRATION_PATH = {
  ROOT: '/:projectID',
}

const SalesAdministrationRoutes: RouteObject[] = [
  {
    path: '',
    element: <SalesAdministrationSubmenu />,
  },
  {
    path: '',
    children: [
      ...NupRoutes,
      ...AreaVarianceSubmenuRoutes,
      ...BankLoanRoutes,
      ...BridgingLoanRoutes,
      ...CreditControlRoutes,
      ...DownpaymentReminderRoutes,
      ...KeyCollectionRoutes,
      ...LADRoutes,
      ...MOTRoutes,
      ...SubsaleSubmenuRoutes,
      ...ProgressMiscBillingRoutes,
      ...PropertyTitleRoutes,
      ...SalesCancellationRoutes,
      ...SpReminderRoutes,
      ...VacantPossessionRoutes,
      ...SalesContractRoutes,
      ...SalesRegistrationsRoutes,
      ...PurchaserAccountsIndoRoutes,
      ...CollectionRoutes,
      ...UnitBookingRoutes,
      ...StakeholderRoutes,
      ...UnitTransferIndoRoutes,
      ...LoanApprovalRoutes,
      ...LoanReleaseNotificationRoutes,
      ...LoanPaymentRoutes,
      ...RefinancingRoutes,
      ...BuyBackRoutes,
      ...LandDepositRoutes,
      ...PaymentScheduleRoutes,
      ...LoanDifferentialRoutes,
      ...StatutoryDocumentsRoutes,
      ...PurchaserNameTransferRoutes,
      ...CreditControlIndoRoutes,
      ...AreaVarianceIndoRoutes,
    ],
  },
]

export default SalesAdministrationRoutes
